// React
import React from 'react'

// Material UI
import { Grid, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, IconButton, Button, CircularProgress, Typography, Tooltip, Chip } from '@material-ui/core'
import AddBoxIcon from '@material-ui/icons/AddBox'
import FilterListIcon from '@material-ui/icons/FilterList'
import SettingsIcon from '@material-ui/icons/Settings'
import { withSnackbar } from 'notistack'
// Components
import ViewOrCreateDepartmentModal from './ViewOrCreateDepartmentModal'
import GeneralTextField from '../../general/TextField'
import TablePagination from '../../general/TablePagination'
// Utilities
import axios from 'axios'
import { handleInputChange, handleErrors } from '../../../utilities/handleChange'
import { maskPhoneNumber } from '../../../utilities/Helpers'
import theme from '../../../utilities/theme'
import { OpenSnackBar } from '../../../utilities/handleSnackBar'
import { ValidateAndTransformToBackendPhoneNumber } from '../../../utilities/Validate'
import { Timezones } from '../../../utilities/Options'
import { sortTableData } from '../../../utilities/sorthelper'
import ToggleIconButton from '../../../utilities/togglebutton'
import DepartmentsFilterModal from './DepartmentsFilterModal'
import { nanoid } from 'nanoid'


const AUTOMATED_DISPATCHING_DEPARTMENTS = 'automatedDispatchingDepartments'
const FIREPITCH_ACTIVE_DEPARTMENTS = 'firepitchActiveDepartments'
const FIREPITCH_INACTIVE_DEPARTMENTS = 'firepitchInactiveDepartments'
const SMS_ENABLED_DEPARTMENTS = 'smsEnabledDepartments'
const PHONE_CALL_ENABLED_DEPARTMENTS = 'phoneCallsEnabledDepartments'
const WEATHER_ENABLED_DEPARTMENTS = 'weatherEnabledDepartments'
const DEACTIVATED_DEPARTMENTS = 'deactivatedDepartments'
const PTT_DEPARTMENTS = 'pttDepartments'
const ALLOW_USER_REGISTRATION_BY_CODE_DEPARTMENTS = 'userRegistrationByCodeDepartments'

const filterOptions = [
    { text: 'Automated Dispatching', value: AUTOMATED_DISPATCHING_DEPARTMENTS },
    { text: 'Deactivated', value: DEACTIVATED_DEPARTMENTS },
    { text: 'FirePITCH Active', value: FIREPITCH_ACTIVE_DEPARTMENTS },
    { text: 'FirePITCH Inactive', value: FIREPITCH_INACTIVE_DEPARTMENTS },
    { text: 'Phone Calls Enabled', value: PHONE_CALL_ENABLED_DEPARTMENTS },
    { text: 'SMS Enabled', value: SMS_ENABLED_DEPARTMENTS },
    { text: 'Weather Enabled', value: WEATHER_ENABLED_DEPARTMENTS },
    { text: 'Push To Talk Enabled', value: PTT_DEPARTMENTS },
    { text: 'User Registration By Code', value: ALLOW_USER_REGISTRATION_BY_CODE_DEPARTMENTS },
]

const initialState = {
    update: false,
    toneFields: [],
    departments: [],
    callTypes: [],
    defaultCallTypes: [],
    nonDefaultCallTypes: [],
    radioChannels: [],
    department: {
        allCall: false,
        allCallDepartmentIds: [],
        callTypes: [],
        color: theme.palette.primary.main,
        featuresPermission: {
            manualDispatches: true,
            audioDispatches: false,
            respondDispatches: true,
            chat: true,
            weather: true,
            textNotifications: true,
            phoneCallNotifications: false,
            pushNotifications: true,
            emailNotifications: false,
            pttMobile: false,
            pttWeb: false,
            allowUserRegistrationByCode: false,
            enableUserRegistrationAutoApproval: false
        },
        location: {
            address: {
                addressLine1: null,
                addressLine2: null,
                city: null,
                county: null,
                state: null,
                zip: null
            },
        },
        name: null,
        agency: null,
        phone: null,
        timezone: Timezones.find(tz => tz.text === 'CST')?.value,
        tones: [],
        radioChannelId: null,
        type: null,
        dispatchDuration: 30,
        facebookPages: [],
        smsNumber: "+15014030911",
        useCustomSmsNumber: false
    },
    errors: {
        department: {
            callTypes: [],
            featuresPermission: {},
            location: {
                address: {

                }
            },
            tones: [],
            facebookPages: [],
        },
        info: {

        }
    },
    modals: {
        createNewDepartment: false,
        filterModal: false
    },
    loaders: {
        getDepartments: false,
        getDepartmentOptions: false,
        getCallTypes: false,
        getRadioChannels: false,
        createNewDepartment: false,
        deleteDepartment: false,
        handleCloneClick: false,
    },
    info: {
        search: '',
        page: 1,
        limit: 25,
        pagination: {
            "total": 0,
            "limit": 25,
            "currentPage": 1,
            "totalPages": 0,
            "previous": null,
            "next": null
        }
    },
    departmentOptions: [],
    activeFilters: []
}

class Departments extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ...initialState,
            sortedDepartments: [],
            sortedColumn: '',
            sortOrder: ''
        }
    }
    componentDidMount = async () => {
        this.getCallTypes()
        this.getDepartmentOptions()
        this.getRadioChannels()
        await this.getDepartments()
    }
    //sorting from here 
    handleColumnHeaderClick = (column) => {
        if (column === this.state.sortedColumn) {
            this.setState((prevState) => ({
                sortOrder: prevState.sortOrder === 'asc' ? 'desc' : 'asc'
            }))
        } else {
            this.setState({
                sortedColumn: column,
                sortOrder: 'asc'
            })
        }
    }

    updateSortedDepartments = () => {
        const { departments, sortedColumn, sortOrder } = this.state

        const sortedDepartments = sortTableData(departments, sortedColumn, sortOrder, true)

        this.setState({ sortedDepartments: sortedDepartments })
    }

    componentDidUpdate(prevProps, prevState) {
        const { sortedColumn, sortOrder } = this.state
        if (prevState.departments !== this.state.departments || sortedColumn !== prevState.sortedColumn || sortOrder !== prevState.sortOrder) {
            this.updateSortedDepartments()
        }
    }
    //to here sorting ends
    componentWillUnmount = () => {
        this.props.closeSnackbar()
    }

    addToneField = () => {
        let value = 'department'

        this.setState(prevState => ({
            [value]: {
                ...prevState[value],
                tones: prevState[value].tones.concat({
                    value: null,
                    variance: null,
                    length: null
                })
            },
            errors: {
                ...prevState.errors,
                [value]: {
                    ...prevState.errors[value],
                    tones: prevState.errors[value].tones.concat({
                        value: null,
                        variance: null,
                        length: null
                    })
                }
            }
        }))
    }

    deleteToneField = (index) => {
        let value = 'department'

        this.setState(prevState => ({
            [value]: {
                ...prevState[value],
                tones: prevState[value].tones.filter((tone, i) => {
                    return i !== index
                })
            },
            errors: {
                ...prevState.errors,
                [value]: {
                    ...prevState.errors[value],
                    tones: prevState.errors[value].tones.filter((tone, i) => {
                        return i !== index
                    })
                }
            }
        }))
    }

    getDepartments = async () => {
        const { loaders, info } = this.state

        if (!loaders.getDepartments) {

            this.setState(prevState => ({ loaders: { ...prevState.loaders, getDepartments: true } }))

            try {
                const { accessToken } = this.props
                const { page, limit, search } = info
                const config = {
                    headers: {
                        Authorization: accessToken
                    },
                    params: {
                        page,
                        limit,
                        search
                    }
                }
                const getDepartmentsResponse = await axios.get('/api/departments', config)
                // console.log({ getDepartmentsResponse })
                if (getDepartmentsResponse.data.success) {

                    let departments = [...getDepartmentsResponse.data.departments]
                    let pagination = getDepartmentsResponse.data.pagination || initialState.info.pagination

                    this.setState(prevState => ({
                        departments,
                        info: { ...prevState.info, pagination },
                        loaders: { ...prevState.loaders, getDepartments: false }
                    }))

                    return
                }
            } catch (error) {
                // console.log({ 'getDepartments error': error })
            }

            this.setState(prevState => ({ loaders: { ...prevState.loaders, getDepartments: false } }))
        }
    }

    getDepartmentOptions = async () => { // this fethces all departments without pagination for dropdowns purpose
        const { loaders } = this.state

        if (!loaders.getDepartmentOptions) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, getDepartmentOptions: true } }))

            try {
                const { accessToken } = this.props
                const config = {
                    headers: {
                        Authorization: accessToken
                    }
                }
                const getDepartmentOptionsResponse = await axios.get('/api/departments', config)
                // console.log({ getDepartmentOptionsResponse })
                if (getDepartmentOptionsResponse.data.success) {
                    // format department options array for all call modal
                    let departments = getDepartmentOptionsResponse.data.departments
                    let departmentOptions = departments.map(department => {
                        return {
                            ...department,
                            text: `${department.name} ${department.agency ?? ''}`,
                            value: department._id
                        }
                    })

                    this.setState(prevState => ({
                        departmentOptions,
                        loaders: { ...prevState.loaders, getDepartmentOptions: false }
                    }))

                    return
                }
            } catch (error) {
                // console.log({ 'getDepartmentOptions error': error })
            }

            this.setState(prevState => ({ loaders: { ...prevState.loaders, getDepartmentOptions: false } }))
        }
    }

    getCallTypes = async () => {
        const { loaders } = this.state
        const { accessToken } = this.props
        const config = {
            headers: {
                Authorization: accessToken
            }
        }

        if (!loaders.getCallTypes) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, getCallTypes: true } }))
            try {
                const getCallTypesResponse = await axios.get('/api/calltypes', config)
                if (getCallTypesResponse && getCallTypesResponse.data && getCallTypesResponse.data.success) {
                    let callTypes = getCallTypesResponse.data.callTypes
                    const defaultCallTypes = callTypes.filter(callType => callType.default).map(callType => ({ text: callType.callType, value: callType._id }))
                    const nonDefaultCallTypes = callTypes.filter(callType => !callType.default).map(callType => ({ text: callType.callType, value: callType._id }))

                    this.setState(prevState => ({
                        callTypes,
                        defaultCallTypes,
                        nonDefaultCallTypes,
                        loaders: { ...prevState.loaders, getCallTypes: false }
                    }))
                } else {
                    this.setState(prevState => ({
                        errors: { ...prevState.errors, messages: getCallTypesResponse.data.errors },
                        loaders: { ...prevState.loaders, getCallTypes: false }
                    }))
                }
            } catch (error) {
                this.setState(prevState => ({
                    loaders: { ...prevState.loaders, getCallTypes: false }
                }))
            }
        }
    }

    getRadioChannels = async () => {
        const { loaders } = this.state
        const { accessToken } = this.props
        const config = {
            headers: {
                Authorization: accessToken
            }
        }

        if (!loaders.getRadioChannels) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, getRadioChannels: true } }))
            try {
                const getRadioChannelsResponse = await axios.get('/api/radioChannel', config)
                if (getRadioChannelsResponse?.data?.success === true) {
                    let radioChannels = getRadioChannelsResponse.data.radioChannels

                    this.setState(prevState => ({
                        radioChannels,
                        loaders: { ...prevState.loaders, getRadioChannels: false }
                    }))
                } else {
                    this.setState(prevState => ({
                        errors: { ...prevState.errors, messages: getRadioChannelsResponse.data.errors },
                        loaders: { ...prevState.loaders, getRadioChannels: false }
                    }))
                }
            } catch (error) {
                this.setState(prevState => ({
                    loaders: { ...prevState.loaders, getRadioChannels: false }
                }))
            }
        }
    }

    createDepartment = async () => {
        const { loaders } = this.state
        const { accessToken } = this.props

        if (!loaders.createNewDepartment) {
            const { success, errors } = this.handleErrors()
            if (success) {
                this.setState(prevState => ({ loaders: { ...prevState.loaders, createNewDepartment: true } }))
                const department = {
                    ...this.state.department,
                    tones: this.state.department.tones.map(tone => {
                        return {
                            ...tone,
                            value: parseFloat(tone.value),
                            variance: parseFloat(tone.variance),
                            length: parseFloat(tone.length)
                        }
                    }),
                    phone: ValidateAndTransformToBackendPhoneNumber(this.state.department.phone),
                    radioChannelId: !this.state.department.radioChannelId ? null : this.state.department.radioChannelId,
                    alertDepartmentForFirepitchContactNumber: ValidateAndTransformToBackendPhoneNumber(this.state.department.alertDepartmentForFirepitchContactNumber)
                }

                const config = {
                    headers: {
                        Authorization: accessToken
                    }
                }
                try {
                    const createDepartmentResponse = await axios.post('/api/departments', department, config)
                    if (createDepartmentResponse && createDepartmentResponse.data && createDepartmentResponse.data.success) {
                        this.setState(prevState => ({
                            loaders: { ...prevState.loaders, createNewDepartment: false },
                            departments: [...prevState.departments, createDepartmentResponse.data.department],
                            departmentOptions: [...prevState.departmentOptions, {
                                text: createDepartmentResponse.data.department.name,
                                value: createDepartmentResponse.data.department._id
                            }]
                        }))
                        this.handleModal('createNewDepartment')
                        OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "Department created successfully", "success")
                    } else {
                        this.setState(prevState => ({
                            errors: { ...prevState.errors, messages: createDepartmentResponse.data.errors },
                            loaders: { ...prevState.loaders, createNewDepartment: false },
                        }))
                    }
                } catch (e) {
                    this.setState(prevState => ({
                        loaders: { ...prevState.loaders, createNewDepartment: false }
                    }))
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
                }
            } else {
                alert('Please fill out all of the required fields.')
                this.setState({ errors })
            }
        }
    }

    updateDepartment = async () => {
        const { department, loaders } = this.state

        if (!loaders.createNewDepartment) {
            const { success, errors } = this.handleErrors()

            if (success) {
                this.setState(prevState => ({ loaders: { ...prevState.loaders, createNewDepartment: true } }))
                const config = {
                    headers: {
                        Authorization: this.props.accessToken
                    }
                }
                // reformat the deparment before sending to put route
                let formattedDepartment = {
                    ...department,
                    phone: ValidateAndTransformToBackendPhoneNumber(department.phone),
                    tones: (department?.tones || []).map(tone => ({ ...tone, variance: parseFloat(tone.variance), value: parseFloat(tone.value), length: parseFloat(tone.length) })),
                    radioChannelId: !department.radioChannelId ? null : department.radioChannelId,
                    alertDepartmentForFirepitchContactNumber: ValidateAndTransformToBackendPhoneNumber(department.alertDepartmentForFirepitchContactNumber)
                }
                try {
                    const updateDepartmentResponse = await axios.put(`/api/departments/${department._id}`, formattedDepartment, config)
                    // console.log({ updateDepartmentResponse })

                    if (updateDepartmentResponse.data.success) {
                        this.setState(prevState => ({
                            loaders: { ...prevState.loaders, createNewDepartment: false },
                            department: { ...updateDepartmentResponse.data.department },
                            departments: prevState.departments.map(prevDepartment => {
                                if (prevDepartment._id === updateDepartmentResponse.data.department._id) {
                                    return { ...updateDepartmentResponse.data.department }
                                }
                                else return prevDepartment
                            }),
                            departmentOptions: prevState.departmentOptions.map(prevDepartment => {
                                if (prevDepartment.value === updateDepartmentResponse.data.department._id) {
                                    return {
                                        text: updateDepartmentResponse.data.department.name,
                                        value: updateDepartmentResponse.data.department._id
                                    }
                                }

                                return prevDepartment
                            })
                        }))
                        OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "Department updated successfully", "success")
                        return true
                    } else {
                        this.setState(prevState => ({ loaders: { ...prevState.loaders, createNewDepartment: false } }))
                    }
                } catch (e) {
                    this.setState(prevState => ({
                        loaders: { ...prevState.loaders, createNewDepartment: false }
                    }))
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
                }
            } else {
                alert('Please fill out all of the required fields.')
                this.setState({ errors })
            }
        }
    }

    handleErrorsOnModal = tab => {
        const { success, errors } = this.handleErrors(tab)
        if (success) return true
        else {
            alert('Please fill out all of the required fields.')
            this.setState({ errors })
            return false
        }
    }

    handleErrors = tab => {
        const { department } = this.state
        // note: array index corresponds to current tab on Create Department modal.
        const infoTabFields = [
            {
                path: 'department.name',
                type: 'string',
                shouldCheck: true
            },
            {
                path: 'department.agency',
                type: 'string',
                shouldCheck: !!department?.agency
            },
            {
                path: 'department.phone',
                type: 'string',
                shouldCheck: !!department.phone,
                validation: !!ValidateAndTransformToBackendPhoneNumber(department.phone),
                validationMessage: "Please provide a valid phone number."
            },
            {
                path: 'department.type',
                type: 'string',
                shouldCheck: true
            },
            {
                path: 'department.timezone',
                type: 'string',
                shouldCheck: true
            },
            {
                path: 'department.location.address.addressLine1',
                type: 'string',
                shouldCheck: true
            },
            {
                path: 'department.location.address.city',
                type: 'string',
                shouldCheck: true
            },
            {
                path: 'department.location.address.state',
                type: 'string',
                shouldCheck: true
            },
            {
                path: 'department.location.address.zip',
                type: 'string',
                shouldCheck: true
            },
            {
                path: 'department.color',
                type: 'string',
                shouldCheck: true
            }
        ]
        const calltypesTabFields = [
            {
                path: 'department.callTypes',
                type: 'objectArray',
                keys: {
                    callTypeId: {
                        type: 'string',
                        shouldCheck: true
                    }
                },
                shouldCheck: department.callTypes?.length > 0
            }
        ]
        const firepitchTabFields = [
            {
                path: 'department.tones',
                type: 'objectArray',
                keys: {
                    length: {
                        shouldCheck: true
                    },
                    type: {
                        type: 'string',
                        shouldCheck: true
                    },
                    value: {
                        shouldCheck: true
                    },
                    variance: {
                        shouldCheck: true
                    }
                },
                shouldCheck: department.tones?.length > 0
            },
            {
                path: 'department.alertDepartmentForFirepitchContactNumber',
                type: 'string',
                shouldCheck: department.alertDepartmentForFirepitch,
                validation: !!ValidateAndTransformToBackendPhoneNumber(department.alertDepartmentForFirepitchContactNumber),
                validationMessage: "Please provide a valid phone number.",
            }
        ]
        let fieldsByTab = {
            'info': infoTabFields,
            'calltypes': calltypesTabFields,
            'firepitch': firepitchTabFields
        }
        let allFields = [...infoTabFields, ...calltypesTabFields, ...firepitchTabFields]
        let errors = { ...this.state.errors }
        let frontEndErrors = handleErrors(this.state, errors, tab ? fieldsByTab[tab] : allFields)
        return frontEndErrors
    }

    handleInputChange = (e, data) => {
        this.setState(handleInputChange(e, data, this.state))
    }

    handleFloatInputChange = (e, data) => {
        const parseFloat = (str, decimalPoints = 2) => {
            str = str.toString()
            str = str.slice(0, str.includes(".") ? ((str.indexOf(".")) + decimalPoints + 1) : str.length)
            return str
        }
        this.setState(handleInputChange(e, { ...data, value: parseFloat(data.value || "") }, this.state))
    }

    handleCloneClick = async departmentId => {
        const { loaders } = this.state
        const { accessToken } = this.props

        if (!loaders.handleCloneClick) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, handleCloneClick: true } }))
            try {
                const config = {
                    headers: {
                        Authorization: accessToken
                    }
                }
                const cloneDepartmentResponse = await axios.post(`/api/departments/${departmentId}/clone`, null, config)

                const clonedDepartment = cloneDepartmentResponse.data.clonedDepartment
                // Handle success: Update UI or perform any other necessary actions
                this.setState(prevState => ({
                    loaders: { ...prevState.loaders, handleCloneClick: false },
                    departments: [...prevState.departments, clonedDepartment],
                    departmentOptions: [
                        ...prevState.departmentOptions,
                        { text: clonedDepartment.name, value: clonedDepartment._id }
                    ]
                }))
                OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "Department cloned successfully", "success")
                this.openEditDepartmentModal(clonedDepartment, false)
            } catch (error) {
                OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, error.response?.data?.errors?.[0]?.message || "Error cloning department", "error")
                this.setState(prevState => ({
                    loaders: { ...prevState.loaders, handleCloneClick: false }
                }))
            }
        }
    }

    handleModal = async modal => {
        const { defaultCallTypes, modals } = this.state

        switch (modal) {
            case 'createNewDepartment':
                this.setState(prevState => ({
                    modals: {
                        ...prevState.modals,
                        createNewDepartment: modals.createNewDepartment === true ? false : true
                    },
                    department: {
                        ...initialState.department,
                        callTypes: defaultCallTypes.map(callType => ({ callTypeId: callType.value, mutualAid: [] }))
                    },
                    errors: {
                        ...initialState.errors,
                        department: {
                            ...initialState.errors.department,
                            callTypes: defaultCallTypes.map(callType => ({ callTypeId: null, mutualAid: null }))
                        }
                    },
                    update: false
                }))
                break
            default:
                this.setState(prevState => ({ modals: { ...prevState.modals, [modal]: !prevState.modals[modal] }, update: false }))
        }
    }

    searchDepartments = () => {
        this.setState(prevState => ({
            info: {
                ...prevState.info,
                page: 1
            }
        }), this.getDepartments)
    }

    resetSearch = () => {
        this.setState(prevState => ({
            info: {
                ...prevState.info,
                search: ''
            }
        }), this.getDepartments)
    }

    openEditDepartmentModal = (department) => {
        this.setState(prevState => ({
            department: {
                ...department,
                facebookPages: (department.facebookPages ?? []).map(page => ({ ...page, key: nanoid() }))
            },
            modals: {
                ...prevState.modals,
                createNewDepartment: true
            },
            update: true,
            // set the errors object for all tone fields that already exist on this department. (otherwise there will be an error if there is more than one tone on the deparment)
            errors: {
                ...prevState.errors,
                department: {
                    ...prevState.errors.department,
                    callTypes: department.callTypes.map(callType => { return { callTypeId: null, mutualAid: null } }),
                    tones: department.tones.map(tone => {
                        return {
                            type: null,
                            value: null,
                            variance: null,
                            length: null
                        }
                    })
                }
            }
        }))
    }

    deleteDepartment = async () => {
        const { loaders, department } = this.state
        const { accessToken } = this.props
        const config = {
            headers: {
                Authorization: accessToken
            }
        }

        if (!loaders.deleteDepartment) {
            this.setState(prevState => ({ loaders: { ...prevState.loaders, deleteDepartment: true } }))
            try {
                const deleteDepartmentResponse = await axios.delete(`/api/departments/${department._id}`, config)
                if (deleteDepartmentResponse && deleteDepartmentResponse.data && deleteDepartmentResponse.data.success) {
                    this.setState(prevState => ({
                        loaders: { ...prevState.loaders, deleteDepartment: false },
                        departments: prevState.departments.filter(obj => { return obj._id !== department._id }),
                        departmentOptions: prevState.departmentOptions.filter(obj => { return obj.value !== department._id })
                    }))
                    this.handleModal('createNewDepartment')
                    OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, "Department deleted successfully", "success")
                } else {
                    this.setState(prevState => ({
                        errors: { ...prevState.errors, messages: deleteDepartmentResponse.data.errors },
                        loaders: { ...prevState.loaders, deleteDepartment: false }
                    }))
                }
            } catch (e) {
                this.setState(prevState => ({
                    loaders: { ...prevState.loaders, deleteDepartment: false }
                }))
                OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
            }
        }
    }

    handleAddCallType = () => {
        return this.setState(prevState => ({
            department: { ...prevState.department, callTypes: [...prevState.department.callTypes, { callTypeId: null, mutualAid: [] }] },
            errors: {
                ...prevState.errors,
                department: {
                    ...prevState.errors.department,
                    callTypes: [...prevState.errors.department.callTypes, {}]
                }
            }
        }))
    }

    handleDeleteCallType = (index) => {
        return this.setState(prevState => ({
            department: { ...prevState.department, callTypes: prevState.department.callTypes.filter((callType, callTypeIndex) => callTypeIndex !== index) },
            errors: {
                ...prevState.errors,
                department: {
                    ...prevState.errors.department,
                    callTypes: prevState.errors.department.callTypes.filter((callType, callTypeIndex) => callTypeIndex !== index)
                }
            }
        }))
    }

    handleColorChange = (color) => {
        this.setState(prevState => ({
            department: {
                ...prevState.department,
                color: color.hex
            }
        }))
    }

    //pagination methods
    handleChangePage = (page) => {
        this.setState(prevState => ({
            info: {
                ...prevState.info,
                page
            }
        }), this.getDepartments)
    }

    handleChangeRowsPerPage = (limit) => {
        this.setState(prevState => ({
            info: {
                ...prevState.info,
                page: 1,
                limit
            }
        }), this.getDepartments)
    }

    setActiveFilters = (value) => {
        const { activeFilters } = this.state

        let updatedActiveFilters = activeFilters.includes(value) ? activeFilters.filter(filterValue => filterValue !== value) : activeFilters.concat(value)

        this.setState({
            activeFilters: updatedActiveFilters
        }, this.filterDepartments)
    }

    resetFilters = () => {
        this.setState({
            activeFilters: initialState.activeFilters
        })
    }

    checkIfFiltersSelected = () => {
        const { activeFilters } = this.state
        return !!activeFilters.length
    }

    filterDepartments = () => {
        const { activeFilters, sortedDepartments } = this.state

        let someFiltersSelected = this.checkIfFiltersSelected()

        let filteredDepartments = sortedDepartments

        if (someFiltersSelected) {
            if (activeFilters.includes(AUTOMATED_DISPATCHING_DEPARTMENTS)) {
                filteredDepartments = filteredDepartments.filter(dept => !!dept.featuresPermission.audioDispatches)
            }
            if (activeFilters.includes(FIREPITCH_ACTIVE_DEPARTMENTS)) {
                const thirtyMinutesAgo = Date.now() - (30 * 60 * 1000)
                filteredDepartments = filteredDepartments.filter(dept => !!dept.featuresPermission.audioDispatches && dept.lastPingedTimestampMilliSeconds > thirtyMinutesAgo)
            }
            if (activeFilters.includes(FIREPITCH_INACTIVE_DEPARTMENTS)) {
                const thirtyMinutesAgo = Date.now() - (30 * 60 * 1000)
                filteredDepartments = filteredDepartments.filter(dept => !!dept.featuresPermission.audioDispatches && dept.lastPingedTimestampMilliSeconds < thirtyMinutesAgo)
            }
            if (activeFilters.includes(SMS_ENABLED_DEPARTMENTS)) {
                filteredDepartments = filteredDepartments.filter(dept => !!dept.featuresPermission.textNotifications)
            }
            if (activeFilters.includes(PHONE_CALL_ENABLED_DEPARTMENTS)) {
                filteredDepartments = filteredDepartments.filter(dept => !!dept.featuresPermission.phoneCallNotifications)
            }
            if (activeFilters.includes(WEATHER_ENABLED_DEPARTMENTS)) {
                filteredDepartments = filteredDepartments.filter(dept => !!dept.featuresPermission.weather)
            }
            if (activeFilters.includes(DEACTIVATED_DEPARTMENTS)) {
                filteredDepartments = filteredDepartments.filter(dept => !dept.activated)
            }
            if (activeFilters.includes(PTT_DEPARTMENTS)) {
                filteredDepartments = filteredDepartments.filter(dept => (!!dept.featuresPermission.pttWeb || !!dept.featuresPermission.pttMobile))
            }
            if (activeFilters.includes(ALLOW_USER_REGISTRATION_BY_CODE_DEPARTMENTS)) {
                filteredDepartments = filteredDepartments.filter(dept => !!dept.featuresPermission.allowUserRegistrationByCode)
            }
        }

        return filteredDepartments
    }

    handleFilterModal = (open) => {
        this.setState(prevState => ({
            modals: {
                ...prevState.modals,
                filterModal: open
            }
        }))
    }

    handleAddFacebookPage = () => {
        return this.setState(prevState => ({
            department: {
                ...prevState.department,
                facebookPages: [
                    ...prevState.department?.facebookPages,
                    {
                        pageId: '',
                        pageName: '',
                        key: nanoid()
                    }
                ]
            },
            errors: {
                ...prevState.errors,
                department: {
                    ...prevState.errors.department,
                    facebookPages: [...prevState.errors.department.facebookPages, {}]
                }
            }
        }))
    }

    handleDeleteFacebookPage = (key) => {
        let index = this.state.department.facebookPages?.findIndex?.(page => page.key === key)
        index = index > -1 ? index : 0

        return this.setState(prevState => ({
            department: {
                ...prevState.department,
                facebookPages: prevState.department.facebookPages.filter((page) => page.key !== key)
            },
            errors: {
                ...prevState.errors,
                department: {
                    ...prevState.errors.department,
                    facebookPages: prevState.errors.department.facebookPages.filter((page, pageIndex) => pageIndex !== index)
                }
            }
        }))
    }

    render() {
        const { authenticatedUser, socket, accessToken, playRadio } = this.props
        const { department, activeFilters, errors, modals, loaders, info, toneFields, update, departmentOptions, nonDefaultCallTypes, defaultCallTypes, radioChannels } = this.state
        const { pagination, limit, page, search } = info

        const filteredDepartments = this.filterDepartments()

        return (
            <>
                <DepartmentsFilterModal
                    open={modals.filterModal}
                    filterOptions={filterOptions}
                    activeFilters={activeFilters}
                    setActiveFilters={this.setActiveFilters}
                    handleFilters={this.filterDepartments}
                    handleFilterModal={this.handleFilterModal}
                />
                {modals.createNewDepartment &&
                    <ViewOrCreateDepartmentModal
                        authenticatedUser={authenticatedUser}
                        accessToken={accessToken}
                        socket={socket}
                        open={modals.createNewDepartment}
                        handleClose={() => this.handleModal('createNewDepartment')}
                        update={update}
                        department={department}
                        departmentOptions={departmentOptions}
                        toneFields={toneFields}
                        defaultCallTypes={defaultCallTypes}
                        nonDefaultCallTypes={nonDefaultCallTypes}
                        radioChannels={radioChannels}
                        loaders={loaders}
                        errors={errors}
                        createDepartment={this.createDepartment}
                        updateDepartment={this.updateDepartment}
                        deleteDepartment={this.deleteDepartment}
                        addToneField={this.addToneField}
                        deleteToneField={this.deleteToneField}
                        handleInputChange={this.handleInputChange}
                        handleFloatInputChange={this.handleFloatInputChange}
                        handleColorChange={this.handleColorChange}
                        handleAddCallType={this.handleAddCallType}
                        handleDeleteCallType={this.handleDeleteCallType}
                        handleErrors={this.handleErrorsOnModal}
                        playRadio={playRadio}
                        handleCloneClick={this.handleCloneClick}
                        handleAddFacebookPage={this.handleAddFacebookPage}
                        handleDeleteFacebookPage={this.handleDeleteFacebookPage}
                    />
                }
                <Grid
                    container
                    item
                    xs={12}
                    style={{ borderRadius: 0, marginBottom: 12, display: 'flex', flex: 1, justifyContent: 'space-between', marginTop: 8 }}
                >
                    <Grid
                        item
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}
                    >
                        <Button
                            onClick={() => this.handleModal('createNewDepartment')}
                            variant='outlined'
                        >
                            <AddBoxIcon
                                style={{ marginRight: 8 }}
                            />
                            Add Department
                        </Button>
                    </Grid>
                    <Grid
                        style={{ display: 'flex', marginRight: 24 }}
                        item
                    >
                        <GeneralTextField
                            format="search"
                            gridStyle={{ width: 350 }}
                            placeholder='Search departments...'
                            statePath={`info.search`}
                            value={info.search}
                            handleInputChange={this.handleInputChange}
                            resetSearch={this.resetSearch}
                            onSearch={this.searchDepartments}
                        />
                        <Tooltip
                            title='Filter users'
                        >
                            <IconButton
                                onClick={() => this.handleFilterModal(true)}
                            >
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                        {!!activeFilters.length ?
                            <Button
                                variant='outlined'
                                onClick={this.resetFilters}
                                style={{ margin: '6px 12px' }}
                            >
                                Clear Filters
                            </Button>
                            :
                            null
                        }
                    </Grid>
                </Grid>
                <TableContainer
                    component={Paper}
                    elevation={0}
                    style={{
                        borderRadius: 0,
                        marginBottom: 64
                    }}
                >
                    <Table
                        style={{ minWidth: 650 }}
                        aria-label='Departments'
                    >
                        <TablePagination
                            showAtTop={true}
                            total={pagination.total}
                            rowsPerPage={limit}
                            page={page}
                            handleChangePage={this.handleChangePage}
                            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Department Name
                                    <ToggleIconButton
                                        onClick={() => this.handleColumnHeaderClick('name')}
                                        selected={this.state.sortedColumn === 'name'}
                                        sortOrder={this.state.sortOrder}
                                    />
                                </TableCell>

                                <TableCell>
                                    Phone Number
                                    <ToggleIconButton
                                        onClick={() => this.handleColumnHeaderClick('phone')}
                                        selected={this.state.sortedColumn === 'phone'}
                                        sortOrder={this.state.sortOrder}
                                    />
                                </TableCell>

                                <TableCell>
                                    County
                                    <ToggleIconButton
                                        onClick={() => this.handleColumnHeaderClick('location.address.county')}
                                        selected={this.state.sortedColumn === 'location.address.county'}
                                        sortOrder={this.state.sortOrder}
                                    />
                                </TableCell>

                                <TableCell>
                                    State
                                    <ToggleIconButton
                                        onClick={() => this.handleColumnHeaderClick('location.address.state')}
                                        selected={this.state.sortedColumn === 'location.address.state'}
                                        sortOrder={this.state.sortOrder}
                                    />
                                </TableCell>

                                <TableCell>
                                    Type
                                    <ToggleIconButton
                                        onClick={() => this.handleColumnHeaderClick('type')}
                                        selected={this.state.sortedColumn === 'type'}
                                        sortOrder={this.state.sortOrder}
                                    />
                                </TableCell>

                                <TableCell align='center'>FirePITCH Status</TableCell>

                                <TableCell align='center'>Department Status</TableCell>

                                <TableCell align='right'>   {/* Your content */}</TableCell>

                            </TableRow>

                        </TableHead>
                        <TableBody>
                            {!loaders.getDepartments && filteredDepartments.map((department) => {
                                const formattedLastUpdatedAudioStatus = department.lastPingedTimestampMilliSeconds ? new Date(department.lastPingedTimestampMilliSeconds) : null
                                const twentyFiveMinutesAgo = Date.now() - 60 * 1000 * 25
                                const audioStatusColor = department.lastPingedTimestampMilliSeconds ? department.lastPingedTimestampMilliSeconds <= twentyFiveMinutesAgo ? '#ff0000' : '#00b300' : 'silver'

                                return (
                                    <TableRow
                                        key={department.name}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => this.openEditDepartmentModal(department)}
                                    >
                                        <TableCell
                                            component='th'
                                        >
                                            {department.name}
                                        </TableCell>
                                        <TableCell
                                            component='th'
                                        >
                                            {maskPhoneNumber(department.phone)}
                                        </TableCell>
                                        <TableCell
                                            component='th'
                                        >
                                            {department.location?.address?.county}
                                        </TableCell>
                                        <TableCell
                                            component='th'
                                        >
                                            {department.location?.address?.state}
                                        </TableCell>
                                        <TableCell
                                            component='th'
                                        >
                                            {department.type}
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {department.featuresPermission?.audioDispatches &&
                                                <Tooltip
                                                    title={formattedLastUpdatedAudioStatus ? `Last update: ${formattedLastUpdatedAudioStatus}` : 'This department is available for audio dispatching but has never received a successful ping from their FirePITCH app.'}
                                                >
                                                    <div
                                                        style={{ width: 20, height: 20, backgroundColor: audioStatusColor, borderRadius: '50%', marginLeft: 'auto', marginRight: 'auto' }}
                                                    />
                                                </Tooltip>
                                            }
                                        </TableCell>
                                        <TableCell
                                            align='center'
                                        >
                                            {department.activated ?
                                                <Chip
                                                    label='Active'
                                                    style={{ backgroundColor: '#00b300', color: '#fff' }}
                                                />
                                                :
                                                <Chip
                                                    label='Deactivated'
                                                    style={{ backgroundColor: '#ff0000', color: '#fff' }}
                                                />
                                            }
                                        </TableCell>
                                        <TableCell
                                            component='th'
                                            align='right'
                                            width='10'
                                        >
                                            <IconButton
                                                onClick={() => this.openEditDepartmentModal(department)}
                                                size='small'
                                                disableFocusRipple={true}
                                                disableRipple={true}
                                            >
                                                <SettingsIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                            {loaders.getDepartments &&
                                <TableRow>
                                    <TableCell
                                        style={{ paddingTop: 96, paddingBottom: 96 }}
                                        colSpan={6}
                                        align="center"
                                    >
                                        <CircularProgress
                                            size={36}
                                        />

                                    </TableCell>
                                </TableRow>
                            }
                            {(!filteredDepartments || !filteredDepartments.length) && !loaders.getDepartments &&
                                <TableRow>
                                    <TableCell
                                        style={{ paddingTop: 96, paddingBottom: 96 }}
                                        colSpan={6}
                                        align="center"
                                    >
                                        <Typography
                                            variant='body1'
                                        >
                                            No departments were found.
                                        </Typography>
                                        {search && search.length &&
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                onClick={() => this.resetSearch()}
                                            >
                                                Reset Search
                                            </Button>
                                        }
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                        <TablePagination
                            total={pagination.total}
                            rowsPerPage={limit}
                            page={page}
                            handleChangePage={this.handleChangePage}
                            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                    </Table>
                </TableContainer>
            </>
        )
    }
}

export default withSnackbar(Departments)