// React
import React from 'react'

// React Router
import { withRouter } from 'react-router-dom'

// Material UI
import { Grid, CssBaseline, Typography, Paper, Button, withStyles, CircularProgress, Box } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

// Components
import GeneralTextField from '../general/TextField'

// Assets
import HomepagePhoto from '../../assets/homepage.jpg'
import LogoIcon from '../../assets/logo.png'

// Utilities
import axios from 'axios'
import { handleInputChange, handleErrors } from '../../utilities/handleChange'
import { ValidateAndTransformEmail } from '../../utilities/Validate'
import { ValidateAndTransformToBackendPhoneNumber } from '../../utilities/Validate'
import theme from '../../utilities/theme'
import { withSnackbar } from 'notistack'
import { OpenSnackBar } from '../../utilities/handleSnackBar'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


const initialState = {
    existingUserCheckDone: false,
    existingUser: false,
    userRegistrationRequestSent: false,
    autoApproved: false,
    registrationInfo: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        departmentCode: '',
        password: '',
        password2: ''
    },
    errors: {
        registrationInfo: {

        }
    },
    loaders: {
        register: false
    },
    errorMessage: ''
}

class UserRegistrationCode extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            ...initialState,
            registrationInfo: {
                ...initialState.registrationInfo,
                departmentCode: props.match.params.departmentCode
            }
        }
    }

    componentWillUnmount() {
        this.props.closeSnackbar()
    }

    handleInputChange = (e, data) => {
        this.setState(handleInputChange(e, data, this.state))
    }

    handleRegisterErrors = () => {
        const { registrationInfo, existingUserCheckDone, existingUser } = this.state
        let errors = { ...this.state.errors }

        const existingUserFields = [

            {
                path: 'registrationInfo.email',
                type: 'string',
                shouldCheck: true,
                validation: !!ValidateAndTransformEmail(registrationInfo.email),
                validationMessage: "Please provide a valid email address."
            },

            {
                path: 'registrationInfo.departmentCode',
                type: 'string',
                shouldCheck: true
            },
        ]
        const nonExistingUserFields = [
            {
                path: 'registrationInfo.firstName',
                type: 'string',
                shouldCheck: true
            },
            {
                path: 'registrationInfo.lastName',
                type: 'string',
                shouldCheck: true
            },
            {
                path: 'registrationInfo.phone',
                type: 'string',
                shouldCheck: true,
                validation: !!ValidateAndTransformToBackendPhoneNumber(registrationInfo.phone),
                validationMessage: "Please provide a valid phone."
            },
            {
                path: 'registrationInfo.password',
                type: 'string',
                shouldCheck: true,
                validation: registrationInfo.password && registrationInfo.password.length > 6,
                validationMessage: 'Your password must be more than 6 characters.'
            },
            {
                path: 'registrationInfo.password2',
                type: 'string',
                shouldCheck: true,
                validation: registrationInfo.password === registrationInfo.password2,
                validationMessage: 'Passwords do not match.'
            }
        ]
        let fields = [
            ...existingUserFields
        ]
        if (existingUserCheckDone && !existingUser) {
            fields.push(...nonExistingUserFields)
        }

        let frontEndErrors = handleErrors(this.state, errors, fields)
        return frontEndErrors
    }

    getExistingUserCheck = async (e) => {
        e.preventDefault()
        const { registrationInfo } = this.state
        try {
            const { success, errors } = this.handleRegisterErrors()

            if (success) {
                const response = await axios.get(`/api/auth/registrationRequest`, {
                    params: {
                        email: ValidateAndTransformEmail(registrationInfo.email),
                        departmentCode: registrationInfo.departmentCode
                    }
                })
                if (response?.data?.success) {
                    this.setState(prevState => ({
                        ...prevState,
                        existingUser: response?.data?.existingUser,
                        existingUserCheckDone: true
                    }))
                }
            }
            else {
                this.setState(prevState => ({
                    ...prevState,
                    errors
                }))
            }

        } catch (e) {
            OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, e?.response?.data?.errors?.[0]?.message || "Something went wrong", "error")
        }
    }

    handleRegister = async (e) => {
        e.preventDefault()
        const { registrationInfo, loaders, existingUser } = this.state
        const { success, errors } = this.handleRegisterErrors()


        if (success) {
            if (!loaders.register) {
                this.setState(prevState => ({ loaders: { ...prevState.loaders, register: true } }))

                const userInformation = {
                    email: ValidateAndTransformEmail(registrationInfo.email),
                    departmentCode: registrationInfo.departmentCode
                }

                if (!existingUser) {
                    userInformation.firstName = registrationInfo.firstName
                    userInformation.lastName = registrationInfo.lastName
                    userInformation.phone = registrationInfo.phone
                    userInformation.password = registrationInfo.password
                }

                try {
                    let registerResponse = await axios.post(`/api/auth/registrationRequest`, { ...userInformation, existingUser })
                    if (registerResponse?.data?.success) {
                        this.setState(prevState => ({
                            ...initialState,
                            userRegistrationRequestSent: true,
                            autoApproved: registerResponse?.data?.autoApproved
                        }))
                    }
                } catch (error) {
                    this.setState(prevState => ({
                        loaders: {
                            ...prevState.loaders,
                            register: false
                        },
                        errorMessage: !!error?.response?.data?.errors?.length ? error?.response?.data?.errors?.[0]?.message : 'Request failed due to connectivity issues. Check your internet connection or contact support if problem persists.'
                    }))
                }
            }
        }
        else {
            this.setState(prevState => ({
                errors,
                loaders: { ...prevState.loaders, register: false }
            }))
            OpenSnackBar(this.props.enqueueSnackbar, this.props.closeSnackbar, 'Please fill out all fields correctly.', 'error')
        }
    }

    render() {
        const { loaders, existingUserCheckDone, existingUser, userRegistrationRequestSent, registrationInfo, errors, errorMessage, autoApproved } = this.state

        return (
            <>
                <Grid
                    container
                    component='main'
                    style={{ height: '100vh' }}
                >
                    <CssBaseline />
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        md={5}
                        component={Paper}
                        container
                        elevation={6}
                        square
                        style={{ backgroundColor: theme.palette.primary.background }}
                        alignItems='center'
                    >
                        <div
                            style={{
                                flex: 1,
                                margin: '64px 32px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <Grid
                                item
                                container
                                justifyContent='center'
                                alignItems='center'
                                xs={12}
                                style={{ marginBottom: 32, display: 'flex', flexDirection: 'column' }}
                            >
                                <Grid
                                    item
                                    container
                                    justifyContent='center'
                                    alignItems='center'
                                    xs={12}
                                // style={{ marginBottom: 8 }}
                                >
                                    <img
                                        style={{
                                            height: 80,
                                            marginRight: 8,
                                            backgroundColor: theme.palette.primary.background,
                                            color: theme.palette.primary.dark
                                        }}
                                        src={LogoIcon}
                                        alt="FireTEXT"
                                    />
                                    <h1
                                        style={{ fontSize: 54 }}
                                    >
                                        FireTEXT<sup style={{ fontSize: 26, position: 'relative', top: -16 }}>®</sup>
                                    </h1>
                                </Grid>
                                <Typography
                                    variant="body1"
                                    style={{ color: '#212121' }}
                                >
                                    v{require('../../../package.json').version}
                                </Typography>
                            </Grid>
                            {
                                userRegistrationRequestSent ?
                                    <>
                                        <Box display="flex" alignItems="center" mb={3}>
                                            <CheckCircleIcon style={{
                                                color: 'green',
                                                marginRight: theme.spacing(1),
                                                fontSize: 48
                                            }} />
                                            <Typography variant="h6">
                                                {
                                                    autoApproved ?
                                                        `Your registration request has been approved by the administrator.
                                                        You can now login to FireTEXT® Dispatch.`
                                                        :
                                                        `Your registration request has been sent to the administrator. Wait for them to approve the request.`
                                                }
                                            </Typography>
                                        </Box>
                                        <Button
                                            type="button"
                                            variant='contained'
                                            size='small'
                                            onClick={() => window.location.href = '/'}
                                        >
                                            Back to login
                                        </Button>
                                    </>
                                    :
                                    <>
                                        <Typography
                                            variant='h4'
                                            style={{ marginBottom: 24, textAlign: 'left', width: '100%' }}
                                        >
                                            {'Registration Request'}
                                        </Typography>
                                        {existingUserCheckDone &&
                                            <Alert>
                                                {existingUser ?
                                                    'You are already a member of FireTEXT® Dispatch. Send request to join this department.'
                                                    :
                                                    'You are not yet a member of FireTEXT® Dispatch. Provide more details to join this department.'
                                                }
                                            </Alert>
                                        }
                                        {errorMessage &&
                                            <Alert
                                                severity="error"
                                                onClose={() => this.setState({ errorMessage: '' })}
                                                style={{ marginBottom: 16 }}
                                            >
                                                {errorMessage}
                                            </Alert>
                                        }

                                        <form onSubmit={existingUserCheckDone ? this.handleRegister : this.getExistingUserCheck}>
                                            <Grid
                                                style={{ marginTop: 16 }}
                                                xs={12}
                                                spacing={2}
                                                container
                                                item
                                            >
                                                <GeneralTextField
                                                    width={12}
                                                    disabled={true}
                                                    label='Department Code *'
                                                    placeholder='Enter department code...'
                                                    statePath={`registrationInfo.departmentCode`}
                                                    value={registrationInfo.departmentCode}
                                                    error={errors.registrationInfo.departmentCode}
                                                    handleInputChange={this.handleInputChange}
                                                />
                                                <GeneralTextField
                                                    width={12}
                                                    disabled={existingUserCheckDone}
                                                    label='Email Address *'
                                                    placeholder='Enter email address...'
                                                    statePath={`registrationInfo.email`}
                                                    value={registrationInfo.email}
                                                    error={errors.registrationInfo.email}
                                                    handleInputChange={this.handleInputChange}
                                                />
                                                {existingUserCheckDone && !existingUser &&
                                                    <>
                                                        <GeneralTextField
                                                            width={12}
                                                            label='First Name *'
                                                            placeholder='Enter your first name...'
                                                            statePath={`registrationInfo.firstName`}
                                                            value={registrationInfo.firstName}
                                                            error={errors.registrationInfo.firstName}
                                                            handleInputChange={this.handleInputChange}
                                                        />
                                                        <GeneralTextField
                                                            width={12}
                                                            label='Last Name *'
                                                            placeholder='Enter your last name...'
                                                            statePath={`registrationInfo.lastName`}
                                                            value={registrationInfo.lastName}
                                                            error={errors.registrationInfo.lastName}
                                                            handleInputChange={this.handleInputChange}
                                                        />
                                                        <GeneralTextField
                                                            width={12}
                                                            label='Phone Number *'
                                                            format='phoneNumber'
                                                            placeholder='Enter phone number...'
                                                            statePath={`registrationInfo.phone`}
                                                            value={registrationInfo.phone}
                                                            error={errors.registrationInfo.phone}
                                                            handleInputChange={this.handleInputChange}
                                                        />

                                                        <GeneralTextField
                                                            width={12}
                                                            label='Password *'
                                                            placeholder='Enter password...'
                                                            type='password'
                                                            statePath={`registrationInfo.password`}
                                                            value={registrationInfo.password}
                                                            error={errors.registrationInfo.password}
                                                            handleInputChange={this.handleInputChange}
                                                        />
                                                        <GeneralTextField
                                                            width={12}
                                                            label='Confirm Password *'
                                                            placeholder='Enter password again...'
                                                            type='password'
                                                            statePath={`registrationInfo.password2`}
                                                            value={registrationInfo.password2}
                                                            error={errors.registrationInfo.password2}
                                                            handleInputChange={this.handleInputChange}
                                                        />
                                                    </>
                                                }

                                                <Grid
                                                    xs={12}
                                                    item
                                                >
                                                    <Button
                                                        type="submit"
                                                        fullWidth
                                                        color='secondary'
                                                        variant='contained'
                                                    >
                                                        {loaders.register &&
                                                            <CircularProgress
                                                                size={18}
                                                                style={{ marginRight: 8 }}
                                                            />
                                                        }
                                                        {existingUserCheckDone ? "Send registration request" : "Continue"}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </form>
                                    </>}
                        </div>
                    </Grid>
                    <Grid
                        container
                        item
                        alignItems='center'
                        justifyContent='center'
                        xs={false}
                        sm={4}
                        md={7}
                        style={{
                            backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${HomepagePhoto})`,
                            backgroundRepeat: 'no-repeat', backgroundColor: theme.palette.primary.background, backgroundSize: 'cover',
                            backgroundPosition: 'center', padding: 64, display: 'flex', flex: 1, justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >


                        <Typography
                            style={{
                                color: '#fff', position: 'relative', zIndex: 1000, fontSize: 54, background: '#00000080',
                                textShadow: '0px 0px 1px #000000', textStroke: '1px #000000', fontWeight: 'bold',
                                padding: 10, borderRadius: 4
                            }}
                        >
                            Save lives. Respond faster.
                        </Typography>
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default withSnackbar(withRouter(withStyles(null, { withTheme: true })(UserRegistrationCode)))